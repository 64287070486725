<template>
  <div class="table-toast table-accountsLogs border-primary" v-if="!loading.first">
    <div>
      <div class="d-flex justify-content-end table-toast--close">
        <feather-icon icon="XIcon" size="1.5x" @click="closeTable"/>
      </div>
      <div>
        <table-render
          :schema="schemaHistory"
          class="table-toast--table"
          :rows="rowsHistory"
          :striped="false"
          :sticky-header="true"
          id="MultiAccountHistory"
          :loading="loading.history"
          :sortByCustom="orderBy"
        />
        <pagination
          v-if="!loading.first"
          :pagination="pagination"
          :noDigits="false"
          :showSize="false"
          :disabled="loading.history"
        />
      </div>
    </div>
  </div>  
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'table-account-logs',
  props: ['close', 'update'],
  data() {
    return {
      schemaHistory: [],
      loading: {
        first: true,
        history: true
      },
      rowsHistory: [],
      pagination: { page: 1, limit: 10, total: 0 },
      sortProps: null
    }
  },
  computed: {
    ...mapGetters({
      carriersAccountLogs: 'getCarriersAccountLogs'
    })
  },
  watch: {
    carriersAccountLogs(value) {
      this.rowsHistory = value.rows
      this.loading.first = false
      this.loading.history = false
      this.pagination.total = value.pagination.total
      this.$emit('update')
    },
    'pagination.page'() {
      this.getAccountLogs()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getAccountLogs()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schemaHistory = [
        { label: 'Usuario', key: 'user_email', customSort: 'user' },
        { label: 'Fecha Modificación', key: 'updated_at', customSort: 'date' },
        { label: 'Nombre de Cuenta', key: 'account.code' },
        { label: 'Tipo de Contrato', key: 'account.contract_type', style: { width: '30%'} }
      ]
      this.getAccountLogs()
    },
    getAccountLogs() {
      if (!this.loading.first) this.loading.history = true
      let params = { 
        organization_id: this.$route.params?.id,
        simplify: true,
        limit: this.pagination.limit,
        page: this.pagination.page
      }
      if (this.sortProps) params = {
        ...params,
        ...this.sortProps
      }
      const data = {
        name: 'getCarriersAccountLogs', 
        queryParams: {...params},
        onError: (err) => {
          if (this.loading.first) {
            this.$emit('update')
            this.closeTable()
          }
          else this.errorAccountLogs(err)
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    orderBy (sortAs, type) {
      if (sortAs === 'user') this.sortProps = { order_by: 'user_email'}
      else this.sortProps = { order_by: 'updated_at'}
      
      if (!!type) this.sortProps = {...this.sortProps, order_direction: type}
      else this.sortProps = {}
      
      if (this.pagination.page === 1) this.getAccountLogs()
      else this.pagination.page = 1
    },
    errorAccountLogs (e) {
      this.loading.history = false
    },
    closeTable() {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.table-toast{
  background-color: #ffffff;
  position: absolute;
  top: 3.5rem;
  width: 150%;
  right: 0;
  border-radius: 0.5rem;
  font-size: 12px;
  box-shadow: 0px 4px 4px 0px rgb(74 74 74 / 33%);
  
  .table-toast--close{
    top: 0.5rem;
    position: absolute;
    right: 1.5rem;
    z-index: 3;
    svg {
      cursor: pointer;
    }
  }
  .table-toast--table{
    margin: 0;
    overflow-x: hidden;
    margin-top: 0.5rem;
    #MultiAccountHistory div {
      padding: 0 1px;
      table > thead > tr > th{
        background-color: #ffffff !important;
        border-top: none;
        border-bottom: 1px rgb(212, 212, 212) solid;
        span {
          font-size: 13px;
        }
      }
    }
  }
  
}
.table-accountsLogs {
  .table-render-skeleton{
    overflow-x: hidden;
    td {
      padding: 0;
    }
  }
}
@media (max-width: 648px) {
  .table-toast {
    width: 90%;
  }
}
</style>
