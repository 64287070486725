<template>
  <b-modal
    id="modalCreateRules"
    :title="this.edition ? `Editar ${this.formTab.name}` : 'Agregar nueva regla'"
    :ok-title="this.edition ? `Editar ${this.formTab.name}` : 'Crear regla'"
    ok-variant="warning"
    modal-class="dialog-900"
    ok-only
    centered
    @close="onClose"
    @ok="ok"
    >
    <form-render
      class="mb-2"
      :fields.sync="fields"
      :form.sync="form"
      @send="onAccept"
      :key="keyFormRenderManageDataCompany"
      ref="formRenderAccount"
      containerButtonsClass="col-sm-12 col-lg-4 container-button"
    >
      <template #space />
    </form-render>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CarriersService from '@/views/modules/configuration/carriers/carriers.service'
import ShipperService from '@/views/modules/configuration/shippers/shippers.service'

export default {
  name: 'modal-create-multiaccount',
  props: ['formTab'],
  data() {
    return {
      form: {},
      fields: [],
      fieldFix: [],
      keyFormRenderManageDataCompany: 0,
      optionDelivery: [{ id: 1, text: this.$t('Distribucion normal'), code: 'normal' }, { id: 2, text: this.$t('Devolucion logistica inversa'), code: 'return' }],
      optionServices: [],
      CarrierService: new CarriersService(this),
      ShipperService: new ShipperService(this),
      unfilteredServices: [],
      optionsAccount: [],
      edition: false
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'getCarriersOrganizationShippers',
      carriers: 'getCarriers',
      serviceCarriers: 'getServiceCarriers'
    })
  },
  watch: {
    async formTab() {
      this.edition = !!this.formTab.name

      if (this.edition) {
        this.form = {
          ...this.formTab,
          delivery_type: this.optionDelivery.find(delivery => delivery.code === this.formTab.delivery_type)
        }

        this.fields.unshift({
          fieldType: 'FieldCheckbox',
          name: 'active',
          containerClass: 'col-sm-12 col-md-12 container-info',
          label: 'Activar Regla',
          options: [{ id: 1, text: 'Activar' }],
          //disabled: true,
          //multiple: true,
          align: 'h',
          validation: 'required'
        })


        this.form.carrier_code = this.carriers.rows.find(carrier => carrier.carrier_id === this.formTab.carrier_id)
        this.form.carrier_code.text = this.form.carrier_code.carrier_name

        const queryParams = {
          carrier_id: this.form?.carrier_code.carrier_id
        }

        await this.CarrierService.callService('getServicesCarrier', queryParams).then(resp => {
          const data = resp.data
          this.form.services = data.filter(service => this.formTab.service_code.includes(service.code)).map(service => ({
            text: service.name,
            id: service.id,
            code: service.code,
            default: true,
            ...service
          }))
        })


        this.selectCourier()
        this.selectDelivery()
        this.keyFormRenderManageDataCompany++
      }
    },
    carriers() {
      this.setCouriersOption()
    },
    accounts() {
      this.setAccountsOption()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fieldFix = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre de la regla', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba nombre de regla', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'carrier_code', label: 'Courier', containerClass: 'col-sm-12 container-info col-md-6', validation: 'required', placeholder: 'Selecciona courier', change: this.selectCourier },
        { fieldType: 'FieldSelect', name: 'carrier_organization_shippers_id', label: 'Cuenta asociada', containerClass: 'col-sm-12 container-info col-md-6', validation: 'required', change: this.selectAccount, placeholder: 'Seleccione cuenta', dependency: 'carrier_code' },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1' },
        { fieldType: 'FieldRadio', name: 'delivery_type', label: 'Tipo de envío', containerClass: 'col-sm-6 col-md-12 container-info', options: this.optionDelivery, change: this.selectDelivery, align: 'h', disabled: true, validation: 'required'},
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1' }
      ]

      this.fields = [...this.fieldFix]
      this.setCouriersOption()
      this.keyFormRenderManageDataCompany++
    },
    onClose() {
      this.form = {}
      this.fields = [...this.fieldFix]
      //this.fields.pop()
    },
    selectDelivery(form, value) {
      this.filterServices(this.unfilteredServices)
      this.addFieldServices()
      this.keyFormRenderManageDataCompany++
    },
    selectCourier(form, value) {
      this.keyFormRenderManageDataCompany++
      const queryParams = {
        carrier_id: this.form?.carrier_code.carrier_id
      }

      this.keyFormRenderManageDataCompany++
      this.setAccountsOption()
      this.CarrierService.callService('getServicesCarrier', queryParams).then(resp => {
        // Almacena los servicios sin filtrar
        this.unfilteredServices = resp.data
        // Filtra y mapea los servicios
        this.filterServices(this.unfilteredServices)
        this.filterDelivery(resp.data)
      })
    },
    filterServices(data) {
      if (this.form.delivery_type?.code) {
        const code = this.form.delivery_type.code
        this.optionServices = data.filter(
          service => {
            if (code === 'normal' && service.is_normal) {
              return true
            }
            if (code === 'return' && service.is_return) {
              return true
            }
            return false
          })
          .map(service => ({
            text: service.name,
            id: service.id,
            code: service.code,
            ...service
          }))
        this.addFieldServices()
        this.keyFormRenderManageDataCompany++
      } else {
        this.optionServices = data
          .map(service => ({
            text: service.name,
            id: service.id,
            code: service.code,
            ...service
          }))
        this.keyFormRenderManageDataCompany++
      }
    },
    setCouriersOption() {
      const carrierField = this.fields.find(field => field.name === 'carrier_code')
      carrierField.options = this.carriers?.rows?.map(carrier => ({
        text: carrier.carrier_name,
        id: carrier.carrier_code,
        ...carrier
      }))
    },
    setAccountsOption() {
      const accountField = this.fields.find(field => field.name === 'carrier_organization_shippers_id')
      accountField.options = [...(this.accounts.rows.filter(account => account?.carrier.id === this.form?.carrier_code?.carrier_id &&  account.contract_type === 'pasarela')).map(account => {
        return {
          text: account.code,
          account_name: account.shipper.name,
          date_create: account.created_at,
          carrier_name: account.carrier.name,
          ...account
        }
      }), { text: 'Nueva Cuenta', value: 'created', id: 'created' }]

      if (this.edition) {
        this.form.carrier_organization_shippers_id = accountField.options.find(account => account.id === this.formTab.carrier_organization_shippers_id)
      }
    },
    addFieldServices() {
      const fieldName = 'services'
      const fieldIndex = this.fields.findIndex(field => field.name === fieldName)
      const fieldFixIndex = this.fieldFix.findIndex(field => field.name === fieldName)
      let newField = null

      if (this.optionServices.length > 1) {
        newField = {
          fieldType: 'FieldCheckbox',
          name: fieldName,
          multiple: true,
          containerClass: 'col-sm-12 col-md-12 container-info',
          label: 'Categoria de servicios',
          options: this.optionServices,
          align: 'h',
          useAllCheck: true,
          validation: 'required'
        }
      } else {
        newField = {
          fieldType: 'FieldCheckbox',
          name: fieldName,
          containerClass: 'col-sm-12 col-md-12 container-info',
          label: 'Categoria de servicios',
          options: this.optionServices,
          disabled: true,
          multiple: true,
          align: 'h',
          validation: 'required'
        }
        this.form.services = this.optionServices
      }

      if (fieldIndex > -1) {
        // If the field exists, replace it.
        this.fields.splice(fieldIndex, 1, newField)
        this.fieldFix.splice(fieldFixIndex, 1, newField)
        this.keyFormRenderManageDataCompany++
      } else {
        // If the field does not exist, add it.
        this.fields.push(newField)
        this.fieldFix.push(newField)
        this.keyFormRenderManageDataCompany++
      }
    },
    filterDelivery(data) {
      const isReturnExists = data.some(obj => obj.is_return)
      const isNormalExists = data.some(obj => obj.is_normal)

      if (isReturnExists && isNormalExists) {
        this.fields.find(field => field.name === 'delivery_type').disabled = false
      } else if (isReturnExists) {

        this.fields.find(field => field.name === 'delivery_type').options = [
          { id: 1, text: this.$t('Distribucion normal'), code: 'normal', default: true }
        ]
        this.fields.find(field => field.name === 'delivery_type').disabled = true
      } else if (isNormalExists) {
        this.fields.find(field => field.name === 'delivery_type').options = [
          { id: 1, text: this.$t('Devolucion logistica inversa'), code: 'return', default: true }
        ]
        this.fields.find(field => field.name === 'delivery_type').disabled = true
      } else {
        this.$bvModal.hide('modalCreateRules')
        this.$alert('No se pueden crear reglas con este courier, comuniquese con support', 'Error', 'error')
      }

      this.keyFormRenderManageDataCompany++
    },
    selectAccount(form, value) {

      const queryParams = {
        carrier_id: this.form.carrier_code.carrier_id
      }

      const newField = {
        fieldType: 'FieldInput',
        name: 'code',
        placeholder: 'Ingrese el codigo de la cuenta',
        label: 'Nombre nueva cuenta asociada',
        containerClass: 'col-md-6 col-sm-12 container-info',
        validation: 'required'
      }

      // Hacer una copia de this.fields
      const updatedFields = [...this.fields]
      // Eliminar los campos nuevos que no están presentes en this.fieldFix
      updatedFields.splice(this.fieldFix.length)
      // Agregar los nuevos campos
      if (value.value === 'created') {
        this.CarrierService.callService('getCarrier', queryParams, { carrier_id:  this.form.carrier_code.carrier_id })
          .then(resp => {
            const accountCodeIndex = updatedFields.findIndex(field => field.name === 'carrier_organization_shippers_id')
            updatedFields.splice(accountCodeIndex + 2, 0, newField)
            resp.data.account_params.forEach((field, index) => updatedFields.splice(accountCodeIndex + 3 + index, 0,
              {
                fieldType: 'FieldInput',
                name: field.name,
                placeholder: field.example,
                label: field.name,
                containerClass: 'col-md-6 col-sm-12 container-info'
              }
            )
            )
          })
          .catch(err => {
            console.error(err)
            this.$alert(this.$t('msg-problema-actualizar', { code: err }))
          })
      }


      // Actualizar this.fields con la copia actualizada
      this.fields = updatedFields
      this.keyFormRenderManageDataCompany++
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderAccount.checkForm()
    },
    onAccept(form) {
      const dataForCreateAccount = Object.keys(form).filter(key => !this.fieldFix.some(field => field.name === key)).reduce((obj, key) => {
        obj[key] = form[key]
        return obj
      }, {})

      if (this.edition) {
        return this.updateRule(form)
      }

      if (dataForCreateAccount?.code) {
        this.createAccount(dataForCreateAccount, form)
      } else {
        this.createRule(form)
      }
    },
    createAccount(data, form) {
      const { code, ...rest } = data
      const queryParams = {
        customization: {
          'default-account': true
        },
        code,
        contract_type: 'Pasarela',
        data: {},
        carrier_code: form.carrier_code.carrier_code,
        shipper_id: parseInt(this.$route.params.id),
        organization_id: parseInt(this.$route.params.id),
        credentials: {
          ...rest
        }
      }

      this.ShipperService.callService('createCarriersAccount', queryParams).then(resp => {
        this.createRule({...this.form, carrier_organization_shippers_id: { id: resp.data.id } })
      }).catch(err => {
        console.error(err)
        this.$alert(this.$t('msg-problema-actualizar', { code: err }))
      })
    },
    createRule(form) {
      const queryParams = {
        service_code: form.services.map(service => service.code),
        delivery_type: form.delivery_type.code,
        company_id: parseInt(this.$route.params.id),
        carrier_organization_shippers_id: form.carrier_organization_shippers_id.id,
        carrier_code: form.carrier_code.carrier_code,
        name: form.name
      }
      this.$store.dispatch('fetchService', {name: 'createShipperRules', queryParams, onSuccess: this.createRuleSuccess })
    },
    updateRule(form) {

      const queryParams = {
        service_code: form.services.map(service => service.code),
        delivery_type: form.delivery_type.code,
        company_id: parseInt(this.$route.params.id),
        carrier_organization_shippers_id: form.carrier_organization_shippers_id.id,
        carrier_code: form.carrier_code.carrier_code,
        name: form.name,
        active : form.active
      }
      this.$store.dispatch('fetchService', {
        name: 'updateShipperRules', queryParams,
        params: { rules_code: this.formTab.code },
        onSuccess: this.createRuleSuccess
      })

    },
    succesCreateAccount() {
      this.createRule(this.form)
    },
    createRuleSuccess() {
      this.$bvModal.hide('modalCreateRules')
      this.onClose()
      this.$success(this.$t('msg-exito-guardar'))
      const queryParams = {
        company_id: parseInt(this.$route.params.id),
        group_by_code: true
      }
      this.$store.dispatch('fetchService', { name: 'getShipperRules', queryParams })
    }
  }
}
</script>

<style>
</style>
